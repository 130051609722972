import { NavLink } from "react-router-dom";
import play from "../../images/play.svg";
import "./Video.css";
import { useEffect, useState } from "react";
import { parseViews } from "../../utils/parsers";

export default function Video(props) {
  let { video, views, product, _id } = props.data;
  const [productData, setProductData] = useState(null);
  
  useEffect(() => {
    const fetchProductData = async () => {
      const data = props.getProduct(product);
      setProductData(data);
    };
    
    fetchProductData();
  }, [product, props.getProduct]);

  const handleDelete = async (e) => {
    e.preventDefault(); // Prevent navigation when clicking delete
    try {
      await props.deleteReview(props.data);
      if (props.onDelete) {
        props.onDelete(_id);
      }
      // Reload the page after successful deletion
      window.location.reload();
    } catch (error) {
      console.error('Error deleting review:', error);
    }
  };

  if (!productData) {
    return <div className="video video--loading">Loading...</div>;
  }

  if (video.includes("watch?v=")) {
    video = video.replace("watch?v=", "embed/");
  }

  const parsedViews = parseViews(views);

  return (
    <NavLink 
      className={`video ${props.isSmall ? "video_small" : ""}`}
      to={`/review?id=${_id}`}
    >
      <div className="video__container">
        <iframe 
          className="video__vid"
          src={video}
          title={productData.name}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          muted
        />
        <div className="video__views">
          <img 
            className="video__view-icon"
            src={play}
            alt="Play count"
          />
          {parsedViews}
        </div>
      </div>
      <div className="video__info">
        <h4 className="video__title">{productData.name}</h4>
        <h5 className="video__price">{productData.price}₽</h5>
      </div>
    </NavLink>
  );
}