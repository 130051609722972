import { NavLink, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import "./Gallery.css";
import ProductCard from "../../ProductCard/ProductCard";
import Video from "../../Video/Video";
import MultiSelect from "../../MultiSelect/MultiSelect";
import { getUniqueItems } from "../../../utils/uniqueItems";

export default function Gallery(props) {
  const DEFAULT_MAX_PRICE = 1000000;
  
  // Filter states
  const [isNew, setIsNew] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [selectedColors, setColors] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(DEFAULT_MAX_PRICE);
  const [maxPriceInput, setMaxPriceInput] = useState("");
  const [appliedFilters, setAppliedFilters] = useState({
    isNew: false,
    discount: false,
    selectedColors: [],
    minPrice: 0,
    maxPrice: DEFAULT_MAX_PRICE
  });

  const searchParams = useSearchParams();
  const filter = searchParams[0].get("filter");
  const type = searchParams[0].get("type");
  const filtering = searchParams[0].get("filtering");

  // Set initial max price when items load
  useEffect(() => {
    if (props.items.length > 0) {
      const maxItemPrice = Math.max(...props.items.map(item => item.price));
      setMaxPriceInput(maxItemPrice.toString());
    }
  }, [props.items]);

  function getBaseFilter() {
    if (filtering === "category")
      return (item) => item.category === filter || filter === "Новинки";
    if (filtering === "item")
      return (video) => video.product === filter;
    if (filtering === "user")
      return (video) => video.author === filter;
    if (filtering === "search") {
      const newFilter = filter.toLowerCase();
      return (item) => item.name.toLowerCase().includes(newFilter);
    }
    return () => true;
  }

  // Apply additional filters for items
  function applyFilters(items) {
    return items.filter(item => (
      (appliedFilters.selectedColors.length === 0 || appliedFilters.selectedColors.includes(item.color))
      && (item.price >= appliedFilters.minPrice && item.price <= appliedFilters.maxPrice)
      && (!appliedFilters.isNew || item.isNew)
      && (!appliedFilters.discount || item.discount > 0)
    ));
  }

  // Color selection handler
  const selectColors = (changedColor) => {
    if (selectedColors.includes(changedColor)) {
      setColors(selectedColors.filter((el) => el !== changedColor));
    } else {
      setColors([...selectedColors, changedColor]);
    }
  };

  const handleMaxPriceChange = (e) => {
    const value = e.target.value;
    setMaxPriceInput(value);
    setMaxPrice(value === "" ? DEFAULT_MAX_PRICE : Number(value));
  };

  const handleApplyFilters = (e) => {
    e.preventDefault();
    setAppliedFilters({
      isNew,
      discount,
      selectedColors,
      minPrice,
      maxPrice: maxPrice === "" ? DEFAULT_MAX_PRICE : maxPrice
    });
  };

  // Get filtered items
  let items = props.items;
  let videos = props.videos;
  
  if (type === "items") {
    const baseFiltered = items.filter(getBaseFilter());
    items = applyFilters(baseFiltered);
  } else {
    videos = videos.filter(getBaseFilter());
  }

  const colors = getUniqueItems(props.items.map((data) => data.color));

  return (
    <main className="gallery">
      <div className="gallery__header">
        {filtering === "category" && (
          <NavLink className="gallery__link" to={`/items?filter=${filter}`}>
            {filter}
          </NavLink>
        )}
        /
        <span className="gallery__current">
          {type === "items" ? "Все товары" : "Все обзоры"}
        </span>
      </div>

      {type === "items" && (
        <div className="gallery__filters">
          <form className="gallery__filter-form" onSubmit={handleApplyFilters}>
            <label className="gallery__label">
              Новинки
              <input 
                className="gallery__checkbox"
                type="checkbox"
                id="filter-new"
                onChange={(e) => setIsNew(e.target.checked)}
              />
            </label>
            <label className="gallery__label">
              Скидки
              <input 
                className="gallery__checkbox"
                type="checkbox"
                id="filter-discount"
                onChange={(e) => setDiscount(e.target.checked)}
              />
            </label>
            <MultiSelect
              options={colors}
              onSelect={selectColors}
              title="Цвет"
            />
            <label className="gallery__label">
              Цена от 
              <input 
                className="gallery__num-input"
                type="number"
                id="filter-min-price"
                min={0}
                onChange={(e) => setMinPrice(Number(e.target.value))}
                defaultValue={minPrice}
                placeholder="0"
              />
              до 
              <input 
                className="gallery__num-input"
                type="number"
                id="filter-max-price"
                min={0}
                onChange={handleMaxPriceChange}
                value={maxPriceInput}
                placeholder={DEFAULT_MAX_PRICE.toString()}
              />
            </label>
            <button type="submit" className="gallery__apply-button">
              Применить фильтры
            </button>
          </form>
        </div>
      )}

      <div className="gallery__items">
        {type === "items" 
          ? items.map((data, i) => (
              <ProductCard
                data={data}
                key={`product-${i}`}
                addItem={props.addItem}
                likeItem={props.likeItem}
                openLoginModal={props.openLoginModal}
                isLoggedIn={props.isLoggedIn}
              />
            ))
          : videos.map((video, i) => (
              <Video
                data={video}
                key={`video-${i}`}
                getProduct={props.getProduct}
              />
            ))
        }
      </div>
    </main>
  );
}