import React, { useState, useEffect } from 'react';
import './CookieConsent.css';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('hasAcceptedCookies');
    if (!hasAcceptedCookies) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('hasAcceptedCookies', 'true');
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-banner">
      <div className="cookie-content">
        <p className="cookie-text">
          МЫ ИСПОЛЬЗУЕМ COOKIE-ФАЙЛЫ ДЛЯ НАИЛУЧШЕГО ПРЕДСТАВЛЕНИЯ НАШЕГО САЙТА. 
          ПРОДОЛЖАЯ ИСПОЛЬЗОВАТЬ ЭТОТ САЙТ, ВЫ СОГЛАШАЕТЕСЬ С ИСПОЛЬЗОВАНИЕМ COOKIE-ФАЙЛОВ
        </p>
        <button className="cookie-button" onClick={handleAccept}>
          Принять
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;