import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormModal from "../FormModal/FormModal";

function DeleteCurrentProductModal(props) {
  const [productToDelete, setProductToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isOpen) {
      setProductToDelete(props.productId);
    } else {
      setProductToDelete(null);
      setIsDeleting(false);
    }
  }, [props.isOpen, props.productId]);

  async function submit() {
    try {
      setIsDeleting(true);
      await props.onSubmit(productToDelete);
      
      // Close the modal and redirect to the items page
      props.onClose();
      navigate('/items?filter=Новинки');
      
    } catch (error) {
      console.error('Error deleting product:', error);
      setIsDeleting(false);
    }
  }

  return (
    <FormModal
      name={props.name}
      onClose={props.onClose}
      isOpen={props.isOpen}
      title="Удаление товара"
      buttonText={isDeleting ? "Удаление..." : "Удалить"}
      formRef={null}
      isButtonActive={!isDeleting}
      onSubmit={submit}
    >
      <p className="modal__text">
        Вы уверены, что хотите удалить этот товар? Это действие невозможно отменить.
      </p>
    </FormModal>
  );
}

export default DeleteCurrentProductModal;