import { NavLink } from "react-router-dom";
import "./HorizontalItem.css";
import { useEffect, useState, useContext } from "react";
import { CartContext } from "../../contexts/CartContext";

export default function HorizontalItem(props) {
  const { photos, name, price, color, stock, _id } = props.data;
  const { isCart, deleteProduct } = props;
  const [amount, setAmount] = useState(props.amount);
  const cartContext = useContext(CartContext);

  function updateCartAmount(index, newAmount) {
    const newCartAmounts = [...cartContext.cartAmounts];
    newCartAmounts[index] = newAmount;
    
    // Update localStorage
    localStorage.setItem("cartAmounts", JSON.stringify(newCartAmounts));
    
    // Update cart context
    cartContext.setCartAmounts(newCartAmounts);
    
    // Update total items count
    const newTotal = newCartAmounts.reduce((sum, amount) => sum + amount, 0);
    cartContext.setCartItemsNum(newTotal);
  }

  const getInputClassName = () => {
    return `cart-item__input ${amount >= 10 ? 'wide' : ''}`;
  };

  function increaseAmount(e) {
    e.stopPropagation();
    e.preventDefault();

    if (amount >= stock) return;

    const newAmount = amount + 1;
    setAmount(newAmount);
    props.addToTotal(price);

    if (isCart) {
      const index = cartContext.cart.findIndex(item => item._id === _id);
      if (index !== -1) {
        updateCartAmount(index, newAmount);
      }
    }
  }

  function decreaseAmount(e) {
    e.stopPropagation();
    e.preventDefault();

    if (amount === 1) {
      return;
    }

    const newAmount = amount - 1;
    setAmount(newAmount);
    props.addToTotal(-price);

    if (isCart) {
      const index = cartContext.cart.findIndex(item => item._id === _id);
      if (index !== -1) {
        updateCartAmount(index, newAmount);
      }
    }
  }

  const handleDelete = (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    if (deleteProduct) {
      deleteProduct(_id);
      props.addToTotal(-price * amount);
    }
  };

  const handleAddToCart = (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    if (isCart) {
      props.likeItem(_id);
    } else {
      props.addItem(_id);
    }
  };

  return (
    <NavLink className="cart-item" to={`/item?id=${_id}`}>
      <div className="cart-item__main">
        <img 
          className="cart-item__image" 
          src={photos[0]} 
          alt={name}
          crossOrigin="anonymous"
        />
        <div className="cart-item__info">
          <h3 className="cart-item__title">{name}</h3>
          <p className="cart-item__property">Цвет: {color}</p>
        </div>
        <h4 className="cart-item__price">{price}₽</h4>
      </div>
      <div className="cart-item__buttons">
        {isCart && (
          <button
            className="cart-item__text-button"
            type="button"
            onClick={handleDelete}
          >
            Удалить
          </button>
        )}
        <button
          className="cart-item__text-button"
          type="button"
          onClick={handleAddToCart}
        >
          {isCart ? "Сохранить" : "В корзину"}
        </button>
        {isCart && (
      <div className="cart-item__number">
        <button
          className="cart-item__num-button cart-item__num-button_minus"
          type="button"
          onClick={decreaseAmount}
        />
        <input 
          className={getInputClassName()}
          value={amount}
          disabled 
        />
        <button
          className="cart-item__num-button cart-item__num-button_plus"
          type="button"
          onClick={increaseAmount}
        />
      </div>
    )}
      </div>
    </NavLink>
  );
}