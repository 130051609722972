import { useEffect, useRef, useState } from "react";
import { NavLink, useSearchParams, useLocation } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import ProductCard from "../../ProductCard/ProductCard";
import MultiSelect from "../../MultiSelect/MultiSelect";
import Video from "../../Video/Video";
import api from "../../../utils/api";
import { getUniqueItems } from "../../../utils/uniqueItems";
import "./Catalogue.css";

export default function Catalogue({ addItem, likeItem, openLoginModal, isLoggedIn, categories, deleteReview }) {
  const [products, setProducts] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Filter states
  const [isNew, setIsNew] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [selectedColors, setColors] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [filteredItems, setFilteredItems] = useState([]);

  const searchParams = useSearchParams();
  const category = searchParams[0].get("filter");
  
  const itemCarousel = useRef();
  const videoCarousel = useRef();

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [productsResponse, videosResponse] = await Promise.all([
          api.getProducts(),
          api.getVideos() // Assuming you have this endpoint
        ]);
        
        // Extract the products array from the response data
        const productsArray = productsResponse.data || [];
        const videosArray = videosResponse.data || [];
        
        setProducts(productsArray);
        setVideos(videosArray);
        
        // Set initial max price after getting products
        const maxItemPrice = Math.max(...productsArray.map(data => data.price));
        setMaxPrice(maxItemPrice);
      } catch (err) {
        setError("Failed to load data. Please try again later.");
        console.error("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Get product by ID
  const getProduct = (id) => {
    const product = products.find((product) => product._id === id);
    return product || {};
  };

  // Filter items when dependencies change
  useEffect(() => {
    if (products.length) {
      setFilteredItems(
        products.filter((item) => (
          (selectedColors.length === 0 || selectedColors.includes(item.color))
          && (item.price >= minPrice && item.price <= maxPrice)
          && (item.isNew || !isNew)
          && (item.discount > 0 || !discount)
          && (category === "Новинки" ? item.isNew : item.category === category)
        ))
      );
    }
  }, [selectedColors, maxPrice, minPrice, isNew, discount, category, products]);

  // Color selection handler
  const selectColors = (changedColor) => {
    if (selectedColors.includes(changedColor)) {
      setColors(selectedColors.filter((el) => el !== changedColor));
    } else {
      setColors([...selectedColors, changedColor]);
    }
  };

  // Carousel navigation
  const slideNext = (carousel) => {
    const index = carousel.current.state.activeIndex;
    const itemsOnSlide = 5;
    if (index + 1 >= carousel.current.state.transformationSet.length / itemsOnSlide) return;
    carousel.current.slideNext();
  };

  const slidePrev = (carousel) => {
    carousel.current.slidePrev();
  };

  if (isLoading) return <div className="catalogue__loading">Загрузка...</div>;
  if (error) return <div className="catalogue__error">{error}</div>;

  const colors = getUniqueItems(products.map((data) => data.color));
  
  const productCards = filteredItems.map((data, i) => (
    <ProductCard
      data={data}
      key={`product-${i}`}
      addItem={addItem}
      likeItem={likeItem}
      openLoginModal={openLoginModal}
      isLoggedIn={isLoggedIn}
    />
  ));

  const videoItems = videos.map((video, i) => (
    <Video
      data={video}
      key={`video-${i}`}
      deleteReview={deleteReview}
      getProduct={getProduct}
    />
  ));
  return (
    <main className="catalogue">
      <div className="catalogue__categories">
      {categories.map((c, i) => (
  <NavLink
    className={({}) => {
      const currentFilter = searchParams[0].get("filter");
      const isActiveCategory = currentFilter === c.name;
      return `catalogue__category${isActiveCategory ? ' active' : ''}`;
    }}
    to={c.link}
    key={`category-${i}`}
  >
    {c.name}
  </NavLink>
))}
    </div>
      <h2 className="catalogue__title">{category}</h2>
      <section className="catalogue__products">
        {/* <div className="catalogue__filters">
          <form className="catalogue__filter-form">
            <label className="catalogue__label">
              Новинки
              <input 
                className="catalogue__checkbox"
                type="checkbox"
                id="filter-new"
                onChange={(e) => setIsNew(e.target.checked)}
              />
            </label>
            <label className="catalogue__label">
              Скидки
              <input 
                className="catalogue__checkbox"
                type="checkbox"
                id="filter-discount"
                onChange={(e) => setDiscount(e.target.checked)}
              />
            </label>
            <MultiSelect
              options={colors}
              onSelect={selectColors}
              title="Цвет"
            />
            <label className="catalogue__label">
              Цена от 
              <input 
                className="catalogue__num-input"
                type="number"
                id="filter-min-price"
                min={0}
                onChange={(e) => setMinPrice(Number(e.target.value))}
                defaultValue={minPrice}
                placeholder="0"
              />
              до 
              <input 
                className="catalogue__num-input"
                type="number"
                id="filter-max-price"
                min={0}
                onChange={(e) => setMaxPrice(Number(e.target.value))}
                defaultValue={maxPrice}
                placeholder={maxPrice}
              />
            </label>
          </form>
        </div> */}
        <div className="catalogue__category-2">
          <h3 className="catalogue__subtitle">#лучшее</h3>
          <NavLink 
            className="catalogue__more"
            to={`/items/gallery?filtering=category&filter=${category}&type=items`}
          >
            Посмотреть всё
          </NavLink>
        </div>
        <div className="catalogue__gallery catalogue__gallery_scroll">
          <AliceCarousel
            items={productCards}
            paddingLeft={0}
            paddingRight={0}
            mouseTrackingresponsive={{
              0: { items: 2 },
              600: { items: 3 },
              900: { items: 4 },
              1200: { items: 5 }
            }}
            disableButtonsControls={true}
            ref={itemCarousel}
          />
          <button 
            className="catalogue__carousel-btn catalogue__carousel-btn_prev"
            type="button"
            onClick={() => slidePrev(itemCarousel)}
          />
          <button 
            className="catalogue__carousel-btn catalogue__carousel-btn_next"
            type="button"
            onClick={() => slideNext(itemCarousel)}
          />
        </div>
      </section>
      {videos.length > 0 && (
        <section className="catalogue__reviews">
          <div className="catalogue__category-2">
            <h3 className="catalogue__subtitle">#тренды</h3>
            <NavLink 
              className="catalogue__more"
              to={`/items/gallery?filtering=category&filter=${category}&type=videos`}
            >
              Посмотреть всё
            </NavLink>
          </div>
          <div className="catalogue__videos">
            <AliceCarousel
              items={videoItems}
              paddingLeft={0}
              paddingRight={0}
              mouseTrackingresponsive={{
                0: { items: 2 },
                600: { items: 3 },
                900: { items: 4 },
                1200: { items: 5 }
              }}
              disableButtonsControls={true}
              ref={videoCarousel}
            />
            <button 
              className="catalogue__carousel-btn catalogue__carousel-btn_prev"
              type="button"
              onClick={() => slidePrev(videoCarousel)}
            />
            <button 
              className="catalogue__carousel-btn catalogue__carousel-btn_next"
              type="button"
              onClick={() => slideNext(videoCarousel)}
            />
          </div>
        </section>
      )}
    </main>
  );
}